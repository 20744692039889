import { ISignupData } from '@asdeporte/apolo-components/cjs/Signup/interfaces'
import { ILoginData } from '@asdeporte/apolo-components/cjs/Login/interfaces'
import { IRecoveryData } from '@asdeporte/apolo-components/cjs/NewPassword/interfaces'
import { USER_AXIOS, USER_AXIOS_FORMDATA } from '../utils/constants'
import { IUserWeb } from 'src/interfaces/user.interface'
import { getSession } from 'next-auth/react'
import {
  IAddress,
  IEmergencyContact,
  ITYRupload,
} from '@asdeporte/apolo-components/cjs/_common/js/dataType'
import dayjs from 'dayjs'
let FormData = require('form-data')

export const signupUser = async (user: ISignupData) => {
  let birthdate = null
  if (user?.birthdate) {
    birthdate = dayjs(user?.birthdate)
      .set('hour', 6)
      .set('minute', 0)
      .set('second', 0)
      .set('millisecond', 0)
      .toDate()
  }

  const { data } = await USER_AXIOS.post('/', { ...user, birthdate })
  return data
}

export const recoveryPassword = async (email: string) => {
  const { data } = await USER_AXIOS.post<{ message: string; token: string }>(
    '/recovery/password',
    { email, source: 'apolo' }
  )
  return data
}

export const createUser = async (user: Partial<IUserWeb>) => {
  let birthdate = null
  if (user?.birthdate) {
    birthdate = dayjs(user?.birthdate)
      .set('hour', 6)
      .set('minute', 0)
      .set('second', 0)
      .set('millisecond', 0)
      .toDate()
  }

  const { data } = await USER_AXIOS.post(`/`, { ...user, birthdate })
  return data
}

export const updateUser = async (userid: string, user: Partial<IUserWeb>) => {
  let birthdate = null
  if (user?.birthdate) {
    birthdate = dayjs(user?.birthdate)
      .set('hour', 6)
      .set('minute', 0)
      .set('second', 0)
      .set('millisecond', 0)
      .toDate()
  }

  const { data } = await USER_AXIOS.patch(`/${userid}`, { ...user, birthdate })
  return data
}

export const updatePassword = async (
  token: string,
  body: Partial<IRecoveryData>
) => {
  const { data } = await USER_AXIOS.put(`/recovery/validate/${token}`, body)
  return data
}

export const suscribePlus = async (
  userid: string,
  end_date: Date,
  reason: string
) => {
  const session = await getSession()

  const cashierid = session?.user?.asdeporteId

  const { data } = await USER_AXIOS.post('/subscription', {
    userid,
    cashierid,
    end_date,
    reason,
    plan: 'plus',
    source: 'apolo',
  })

  return data
}

export const cancelSuscription = async (
  subscriptionid: string,
  reason: string,
  comment: string
) => {
  const session = await getSession()
  const cashierid = session?.user?.asdeporteId
  const { data } = await USER_AXIOS.delete(`/subscription/${subscriptionid}`, {
    data: {
      cashierid,
      subscriptionid,
      reason,
      comment,
    },
  })

  return data
}

export const saveAddress = async (
  userid: string,
  address: Partial<IAddress>
) => {
  const full_address = getFullAddress(address)

  const { data } = await USER_AXIOS.post(`/address`, {
    ...address,
    userid,
    lat: 'N/A',
    lng: 'N/A',
    country: 'MX',
    full_address,
  })
  return data
}

export const updateAddress = async (
  addressid: string,
  userid: string,
  address: Partial<IAddress>
) => {
  const full_address = getFullAddress(address)

  const { data } = await USER_AXIOS.patch(`/address/${addressid}/${userid}`, {
    ...address,
    full_address,
  })
  return data
}

export const deleteAddress = async (addressid: string, userid: string) => {
  const { data } = await USER_AXIOS.delete(`/address/${addressid}/${userid}`)
  return data
}

export const getFullAddress = (address: Partial<IAddress>) => {
  const full_address = []

  if (address.street_line1) {
    full_address.push(address?.street_line1)
  }

  if (address?.street_line2) {
    full_address.push(address?.street_line2)
  }

  if (address?.city) {
    full_address.push(address?.city)
  }

  if (address?.state) {
    full_address.push(address?.state)
  }

  if (address?.country) {
    full_address.push(address?.country || 'MX')
  }

  if (address?.postal_code) {
    full_address.push(address?.postal_code)
  }

  return full_address.join(', ')
}
export const signIn = async (user: any) => {
  const { data } = await USER_AXIOS.post('/auth/sign-in', user)
  return data
}

export const merge = async (fusionData: any, token: string) => {
  const config = {
    headers: {
      // Aquí puedes definir los encabezados que necesites modificar o agregar
      'x-token-auth': token,
      // Otros encabezados...
    },
  }
  const { data } = await USER_AXIOS.post('/merge', fusionData, config)
  return data
}

export const createEmergencyContact = async (
  userid: string,
  contact: Partial<IEmergencyContact>
) => {
  const { data } = await USER_AXIOS.post(`/emergency-contact`, {
    userid,
    ...contact,
  })
  return data
}

export const updateEmergencyContact = async (
  contactid: string,
  contact: Partial<IEmergencyContact>
) => {
  const { data } = await USER_AXIOS.put(
    `/emergency-contact/${contactid}`,
    contact
  )
  return data
}

export const deleteEmergencyContact = async (contactid: string) => {
  const { data } = await USER_AXIOS.delete(`/emergency-contact/${contactid}`)
  return data
}

export const inbodyImport = async (
  upload: Partial<ITYRupload>,
  eventid: string
) => {
  const session = await getSession()
  const bodyFormData = new FormData()
  bodyFormData.append('file', upload.file)
  bodyFormData.append('eventid', eventid)
  bodyFormData.append('cashierid', session?.user?.asdeporteId)

  const { data } = await USER_AXIOS_FORMDATA.post(
    `/inbody/import`,
    bodyFormData
  )

  return data
}

export const unlinkUser = async (parentid: string, dependantid: string) => {
  const { data } = await USER_AXIOS.delete(
    `${parentid}/dependant/${dependantid}`
  )
  return data
}

export const cancelGracePeriod = async (suscriptionid: string) => {
  const { data } = await USER_AXIOS.delete(
    `/subscription/${suscriptionid}/expired`
  )
  return data
}
